.impressum-container {
    font-size: 20px;
}

.impressum-container h1{
    margin-bottom: 30px;
}

.text-green {
    color: #4FA433;
}

.impressum-container a {
    color: #4FA433;
    font-weight: bold;
    text-decoration: none;
}
@font-face {
    font-family: 'veloce';
    src:    url('veloce.eot?e4hd80');
    src:    url('veloce.eot?e4hd80#iefix') format('embedded-opentype'),
        url('veloce.ttf?e4hd80') format('truetype'),
        url('veloce.woff?e4hd80') format('woff'),
        url('veloce.svg?e4hd80#veloce') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'veloce' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-icon_camera:before {
    content: "\e900";
}
.icon-icon_address:before {
    content: "\e901";
}
.icon-icon_call:before {
    content: "\e902";
}
.icon-icon_delete:before {
    content: "\e903";
}
.icon-icon_from_me:before {
    content: "\e904";
}
.icon-icon_happy:before {
    content: "\e905";
}
.icon-icon_heart:before {
    content: "\e906";
}
.icon-icon_info:before {
    content: "\e907";
}
.icon-icon_left_round:before {
    content: "\e908";
}
.icon-icon_left:before {
    content: "\e909";
}
.icon-icon_menu:before {
    content: "\e90a";
}
.icon-icon_message:before {
    content: "\e90b";
}
.icon-icon_package:before {
    content: "\e90c";
}
.icon-icon_packages:before {
    content: "\e90d";
}
.icon-icon_profile:before {
    content: "\e90e";
}
.icon-icon_right_round:before {
    content: "\e90f";
}
.icon-icon_right:before {
    content: "\e910";
}
.icon-icon_sad:before {
    content: "\e911";
}
.icon-icon_sandwich:before {
    content: "\e912";
}
.icon-icon_shop:before {
    content: "\e913";
}
.icon-icon_soso:before {
    content: "\e914";
}
.icon-icon_to_me:before {
    content: "\e915";
}
.icon-icon_up:before {
    content: "\e916";
}
.icon-icon_down:before {
	content: "+";
}
.icon-icon_down.active:before {
	content: "\2013";
}
.icon-icon_verified:before {
    content: "\e917";
}



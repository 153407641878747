.privacy-policy-container {
    text-align: left;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 30px;
    font-size: 20px;
    overflow: hidden;
    color: #fff;
}

.privacy-policy-container h1{
    margin-top: 30px;
    margin-bottom: 20px;
    color: #fff;
}

section p{
    /* overflow: hidden; */
    width: 100%;
}

section a {
    color: #4FA433;
    font-weight: bold;
    text-decoration: none;
}

.bigger-header {
    font-size: 50px;
}

b {
    color: #474747;
}
.bannerContainer {
  border-color: #115427;
  border-top: 1px solid #115427;
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
}

.bannerContent {
  max-width: 650px;
  font-size: 0.8em;
  margin: 0;
  text-align: start;
}

.buttonWrapperClass{
  align-self: center;
}

.bannerButton {
  color: #115427; 
  background: #E8E226;
  font-size: 14px; 
  padding: 7px 10px;
  margin: 15px;
  border: 1px solid #ccc;
  font-weight: bold;
  border-radius: 4px;
  line-height: 1.42857143;
}

.bannerButton:hover {
  color: #115427; 
  background: #E8E226;
  cursor: pointer;
}

.bannerLink {
  color: #115427;
  text-decoration: none;
  font-weight: 500;
}